import { useEffect, useState } from "react";
import RightSideDetails from "../global/rightSideDetails";
import * as helper from '../global/helper';
import defaultUserImg from '../../assets/images/user.jpg'
import ImageCropper from "../global/imageCropper";
import { uploadPublicFile } from "../api/fileAPI";
import swal from 'sweetalert2';
import { getPlayer, postPlayer } from "../api/golfClubAPI";

const Player = (props) => {

    const [playerDetails,       setPlayerDetails]       = useState({TeeBox: 'White', IsAvailableForAllDay: 'Y', PglLastYearParticipant: 'Y', Gender: 'Male'});
    const [playerList,          setPlayerList]          = useState({});
    const [isInit,              setIsInit]              = useState(true);
    const [rightSideDetails,    setRightSideDetails]    = useState(false);
    const [cropperData,         setCropperData]         = useState(null);
    const [userPhotoBlob,       setUserPhotoBlob]       = useState(null);

    useEffect(() => {
        props.title("Players", "player")   
        fetchPlayer();
    }, []);

    useEffect(() => {
        console.log(playerDetails)
    }, [playerDetails])

    useEffect(() => {  
        rightSideDetails ? Config.Core.OpenDrawer() : setPlayerDetails({TeeBox: 'White', IsAvailableForAllDay: 'Y', PglLastYearParticipant: 'Y', Gender: 'Male'});
    }, [rightSideDetails]);

    useEffect(() => {    

        if (!isInit) {
            fillDataTable();
        }
    }, [playerList]);

    const openCropper = () =>{
        setCropperData({
            imageRatio  : { width : 200, height : 200},
            dataHandler : (val) =>  setUserPhotoBlob(val),
            header : 'Upload Profile Photo'
        })
    }

    const removePhotoHandler = async(e) => {
		e.preventDefault();
		setUserPhotoBlob(null)
	}

    const fillDataTable = () =>{
        const dataTable = $('#tblPlayer').KTDatatable({
            data: {
                saveState: false,
                source: playerList,
                pageSize: 20
            },
            layout: {
                scroll: true,
                height: $(window).height() - 250,
                footer: false,
            },
            sortable: true,
            pagination: true,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'DisplayName',
                    title: 'Players',
                    template: function (row) {
                        return `<div class="kt-user-card-v2">
                                <div class="kt-user-card-v2__pic">
                                    ${(row.Photo && row.Photo != '') ? `<img src="${row.Photo}" height="40" width="40" alt="banner-image" >` : ` <div class="kt-badge kt-badge--xl kt-badge--info">  ${row.DisplayName.substring(0, 1)}</div>`}
                                </div>
                                <div class="kt-user-card-v2__details text-clip">
                                    <span class="kt-user-card-v2__name text-clip"> ${row.DisplayName} </span>
                                    <span class="kt-user-card-v2__desc text-clip">Handicap: ${row.Handicap < 0 ? `+ ${Math.abs(row.Handicap)}`: row.Handicap} </span>
                                </div>
                            </div>`
                    }
                },
                {
                    field: 'MemberShipNo',
                    title: 'Membership No.',
                },
                {
                    field: 'CategoryName',
                    title: 'Category',
                    template: function (row) {
                        return `<span class="kt-badge kt-badge--${row.CategoryName == 'Gold' ? 'gold' : row.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width margin-r50">
                                    ${row.CategoryName}
                                </span>`
                    }
                },
                {
                    field: 'IsOwner',
                    title: 'Owner',
                    template: function (row) {
                        if (row.IsOwner == 'Y') 
                            return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                        else
                            return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                    },
                    width: 120,
                },
                // {
                //     field: 'IsCaptain',
                //     title: 'Captain',
                //     template: function (row) {
                //         if (row.IsCaptain == 'Y') 
                //             return '<span class="kt-badge kt-badge--success kt-badge--pill  padding-lr20 padding-tb10">Yes</span>'
                               
                //         else
                //             return '<span class="kt-badge kt-badge--grey kt-badge--pill  padding-lr20 padding-tb10">No</span>'
                //     },
                //     width: 70,
                // },
            ]
        });

        $('#root .kt-grid--hor').addClass('max-width100');
        $('#tblPlayer .kt-datatable__table').addClass('overflow-x-auto');

        dataTable.on('click', 'tr', function () {
            const index = ($("tr").index(this) - 1);          
            if (dataTable.dataSet[index]) {              
                setPlayerDetails(dataTable.dataSet[index]);  
                setRightSideDetails(true);           
            }                
        });
    }

    const updatePlayerDetails = (field, value) => {
        setPlayerDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const closeDrawer = () =>{
        Config.Core.CloseDrawer();  
        setTimeout(() => {
            setRightSideDetails(false);
        }, 200);
    }

    const fetchPlayer = async() => {
        try {
            const response = await getPlayer()
            isInit ? setIsInit(false) : $('#tblPlayer').KTDatatable().destroy(); 
            setPlayerList(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const addPlayerHandler = async () => {
        const btn = $('#btnSave');
        var form = $('#add-player-form');

        form.validate({
            rules: {
                txtFirstName: {
                    required: true
                },   
                txtLastName: {
                    required: true,
                }, 
                txtEmail: {
                    required: true,
                    email: true
                },  
                txtMobile: {
                    required: "Please enter mobile",
                }, 
                txtMemberShipNo: {
                    required: true,
                },         
                txtMemberShipNo: {
                    required: true,
                },         
                txtHandicap: {
                    required: true,
                    number: true
                },         
            },
            messages: {
                txtFirstName: {
                    required: "Please enter First name"
                },
                txtLastName: {
                    required: "Please enter Last name"
                },
                txtEmail: {
                    required: "Please enter email",
                    email: "Please enter a valid email"
                },
                txtMemberShipNo: {
                    required: "Please enter membership number"
                },
            }
        });

        if (!form.valid()) return;
        helper.StartProcessing(btn);

        try {
            let photo = '';
            if (userPhotoBlob) {
                const res = await uploadPublicFile(userPhotoBlob, 'User');
                photo = res.fileName;
            }else{
                if(playerDetails.Photo) photo = playerDetails.Photo.split('/User/')[1];
            }

            await postPlayer({...playerDetails, Photo: photo});
            helper.StopProcessing(btn);
            setUserPhotoBlob(null)
            closeDrawer();
            fetchPlayer()
            if (playerDetails.ID) {
                swal.fire({
                    toast: true,
                    icon: 'success',
                    titleText: 'Player updated successfully!',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    animation: false,
                    customClass: {
                        popup: 'margin-20',
                    },
                });
            } else {
                swal.fire({
                    icon: 'success',
                    titleText: 'Player added successfully!',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (err) {
            helper.StopProcessing(btn);
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            return;
        }
    }

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile">
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <div className="row align-items-center margin-l10">
                                <div className="col-md-12 kt-margin-b-20-tablet-and-mobile margin-b0">
                                    <div className="kt-input-icon kt-input-icon--left">
                                        <input type="text" className="form-control clientSearch" placeholder="Search Player..." id="generalSearch" />
                                        <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="kt-portlet__head-wrapper">
                                <div className="kt-portlet__head-actions">
                                    <button onClick={() => setRightSideDetails(true)}  type="button" className="btn btn-brand btn-icon-sm text-white d-inline"><i className="la la-plus"></i>New Player</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        <div id="tblPlayer" className="kt-datatable table-striped clickable"></div>
                        {rightSideDetails && <RightSideDetails title="Player Details" onCloseDrawer={closeDrawer}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body padding-t30 padding-b0">
                                    <form className="kt-form" id="add-player-form">
                                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">First Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtFirstName" 
                                                        name="txtFirstName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.FirstName || ''}
                                                        onChange={(event) => updatePlayerDetails('FirstName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Last Name <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtLastName" 
                                                        name="txtLastName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.LastName || ''}
                                                        onChange={(event) => updatePlayerDetails('LastName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Email <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtEmail" 
                                                        name="txtEmail" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.Email  || ''}
                                                        onChange={(event) => updatePlayerDetails('Email', event.target.value)} 
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Mobile <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtMobile" 
                                                        name="txtMobile" 
                                                        type="text" 
                                                        minLength='10'
                                                        maxLength='10'
                                                        className="form-control"
                                                        value={playerDetails.Mobile  || ''}
                                                        onChange={(event) => updatePlayerDetails('Mobile', event.target.value)} 
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Last Year Team</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="LastYearTeamName" 
                                                        name="LastYearTeamName" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.LastYearTeamName || ''}
                                                        onChange={(event) => updatePlayerDetails('LastYearTeamName', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Gender <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <div className="input-group">                                                       
                                                        <select id="ddlGender" name="ddlGender" className="form-control kt-selectpicker"
                                                            onChange={(e) => updatePlayerDetails('Gender', e.target.value)}
                                                            value={playerDetails.Gender}
                                                        >
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Age <span className="red">*</span></label>
                                                    <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="age" 
                                                        name="age" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={playerDetails.Age  || ''}
                                                        onChange={(event) => updatePlayerDetails('Age', event.target.value)} 
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Membership Number <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="txtMemberShipNo" 
                                                        name="txtMemberShipNo" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.MemberShipNo || ''}
                                                        onChange={(event) => updatePlayerDetails('MemberShipNo', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3"> Photo </label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <div className="kt-avatar kt-avatar--outline kt-avatar--changed" id="kt_user_add_avatar">
                                                        <div className="kt-avatar__holder" 
                                                            style={{ backgroundImage : `url( ${userPhotoBlob ? URL.createObjectURL(userPhotoBlob): playerDetails.Photo ? playerDetails.Photo : defaultUserImg})` ,width: "120px", height:"120px" } }>    
                                                        </div>
                                                        <label 
                                                            className="kt-avatar__upload" 
                                                            data-toggle="kt-tooltip"  
                                                            data-original-title="Change avatar"
                                                            onClick ={openCropper}
                                                        >
                                                            <i className="fa fa-pen"></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Handicap <span className="red">*</span></label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtHandicap" 
                                                        name="txtHandicap" 
                                                        type="number" 
                                                        className="form-control"
                                                        value={playerDetails.Handicap !== null && playerDetails.Handicap !== undefined ? playerDetails.Handicap : ''}
                                                        onChange={(event) => updatePlayerDetails('Handicap', event.target.value)} 
                                                    />
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Past Achievement</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtPastAchievement" 
                                                        name="txtPastAchievement" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.PastAchievement || ''}
                                                        onChange={(event) => updatePlayerDetails('PastAchievement', event.target.value)} 
                                                    />
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Point Score Last Year</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input 
                                                        id="txtPointScoreLastYear" 
                                                        name="txtPointScoreLastYear" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.PointScoreLastYear || ''}
                                                        onChange={(event) => updatePlayerDetails('PointScoreLastYear', event.target.value)} 
                                                    />
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">TeeBox <span className="red">*</span></label>
                                                <div className="col-xl-3 col-lg-6">
                                                    <div className="input-group">                                                       
                                                        <select id="ddlTeeBox" name="ddlTeeBox" className="form-control kt-selectpicker"
                                                            onChange={(e) => updatePlayerDetails('TeeBox', e.target.value)}
                                                            value={playerDetails.TeeBox}
                                                        >
                                                            <option value="White">White</option>
                                                            <option value="Gold">Gold</option>
                                                            <option value="Red">Red</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> 
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Pgl LastYear Participant</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkPglLastYearParticipant" 
                                                                name="chkPglLastYearParticipant" 
                                                                type="checkbox" 
                                                                defaultChecked={playerDetails.PglLastYearParticipant == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updatePlayerDetails('PglLastYearParticipant', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>     
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Is Available For All Days</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <span className="kt-switch kt-switch--icon">
                                                        <label>
                                                            <input 
                                                                id="chkActive" 
                                                                name="chkActive" 
                                                                type="checkbox" 
                                                                defaultChecked={playerDetails.IsAvailableForAllDay == 'N' ? '' : 'checked'}
                                                                onChange={(event) => updatePlayerDetails('IsAvailableForAllDay', event.target.checked ? 'Y' : 'N')} 
                                                                />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>  
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Available For All Day</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="AvailableForAllDay" 
                                                        name="AvailableForAllDay" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.AvailableForAllDay || ''}
                                                        onChange={(event) => updatePlayerDetails('AvailableForAllDay', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                            <div className="form-group row">
                                                <label className="col-form-label col-lg-3">Available Only For</label>
                                                <div className="col-xl-6 col-lg-8">
                                                    <input
                                                        id="AvailableOnlyFor" 
                                                        name="AvailableOnlyFor" 
                                                        type="text" 
                                                        className="form-control"
                                                        value={playerDetails.AvailableOnlyFor || ''}
                                                        onChange={(event) => updatePlayerDetails('AvailableOnlyFor', event.target.value)}               
                                                    />						
                                                </div>
                                            </div>   
                                        </div>
                                    </form>
                                </div>
                                <div className="kt-portlet__foot">
                                    <div className="kt-form__actions">
                                        <div className="row">
                                            <div className="col-lg-9 ml-lg-auto">
                                                <button id="btnSave" type="button" className="btn btn-brand d-inline-block" onClick={addPlayerHandler}>Save</button>
                                                <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RightSideDetails>}
                    </div>
                </div>
            </div>
            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
        </>
    )
}

export default Player